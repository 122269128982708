import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import { FeatureToggleName, propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const cashieringDashboardPermissions =
  propertyPermissionKeys.cashiering.dashboard;
const cashieringAccountPermissions = propertyPermissionKeys.cashiering.account;
const cashieringBillingPermissions = propertyPermissionKeys.cashiering.billing;
const cashieringBillingsPermissions =
  propertyPermissionKeys.cashiering.billings;
const cashierPermissions = propertyPermissionKeys.cashier;
const foreignCurrencyExchangePermissions =
  propertyPermissionKeys.foreignCurrencyExchange;

const CASHIERING_MENU_ID = 'menu-cashiering';

export const cashieringDashboardLink = `${acConfig.newFrontendUrls.cashiering}/dashboard`;

export const cashieringMenu: MenuElement = {
  translation: 'MENU.CASHIERING.TITLE',
  icon: IconName.cashiering,
  id: CASHIERING_MENU_ID,
  items: [
    {
      link: cashieringDashboardLink,
      aliases: [
        `${acConfig.newFrontendUrls.cashiering}/selected-accounts/summary`,
        `${acConfig.newFrontendUrls.cashiering}/cashier-closure`,
        `${acConfig.newFrontendUrls.reservations}/cashiering/billing`,
        `${acConfig.newFrontendUrls.reservations}/cashiering/accounts`,
      ],
      id: `${CASHIERING_MENU_ID}-dashboard`,
      code: 'CashierDashboard',
      translation: 'MENU.CASHIERING.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringDashboardPermissions.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}C`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}C`,
      keyCode: 67,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/quick-posting`,
      translation: 'MENU.CASHIERING.ITEMS.QUICK_POSTING',
      id: `${CASHIERING_MENU_ID}-quick-posting`,
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringBillingPermissions.viewQuickPosting,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}3`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}3`,
      keyCode: 51,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/policy/deposits`,
      translation: 'MENU.CASHIERING.ITEMS.POLICY_DASHBOARD',
      id: `${CASHIERING_MENU_ID}-policy-dashboard`,
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringDashboardPermissions.viewPolicyDepositsDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      aliases: [
        `${acConfig.newFrontendUrls.cashiering}/policy/deposits/summary`,
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}4`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}4`,
      keyCode: 52,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/complimentary/rules`,
      translation: 'MENU.CASHIERING.ITEMS.COMPLIMENTARY_RULES',
      id: `${CASHIERING_MENU_ID}-complimentary-rules`,
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringBillingsPermissions.manageComplimentaryRule,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      featureToggles: [
        {
          key: FeatureToggleName.ComplimentaryAccounting,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/posting-journal`,
      translation: 'MENU.CASHIERING.ITEMS.POSTING_JOURNAL',
      id: `${CASHIERING_MENU_ID}-posting-journal`,
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringBillingPermissions.postingJournal,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}J`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}J`,
      keyCode: 74,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/number-list-view`,
      translation: 'MENU.CASHIERING.ITEMS.CASHIER_STATUS',
      id: `${CASHIERING_MENU_ID}-cashier-status`,
      permissionsConfiguration: {
        permissions: [
          {
            key: cashierPermissions.viewCashierStatusDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}U`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}U`,
      keyCode: 85,
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/cashiering`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/cashiering/accounts/new`,
      ],
      id: `${CASHIERING_MENU_ID}-cashiering-account-new`,
      translation: 'MENU.CASHIERING.ITEMS.NEW',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringAccountPermissions.manageAccountSetup,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/dashboard?foreignCurrencyExchange=true`,
      translation: 'MENU.CASHIERING.ITEMS.CURRENCY_EXCHANGE',
      id: `${CASHIERING_MENU_ID}-currency-exchange`,
      permissionsConfiguration: {
        permissions: [
          {
            key: foreignCurrencyExchangePermissions.foreignCurrencyExchange,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}[`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}[`,
      keyCode: 219,
    },
  ],
};
