import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { ContextDropdown } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/ContextDropdown';
import {
  getCurrentPropertyIdentifierColor,
  getPropertyContextById,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { SelectedPropertyInfotipBody } from 'frontend-container/components/Menu/components/SelectedProperty/SelectedPropertyInfotipBody/SelectedPropertyInfotipBody';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { useSharedInfotipPresenter } from '@ac/react-infrastructure';
import { OpeningBehavior, TargetValueObject } from '@ac/web-components';

import { useBusinessDateTime } from '../Context/components/ContextDropdown/BusinessDate';

interface Props {
  color?: string;
}

const menuId = 'menu-property-info';

export const SelectedProperty = ({ color }: Props): JSX.Element | null => {
  const [selectedProperty] = useState<Context | undefined>(() => {
    const propertyContext = getPropertyContextData();
    const propertyId = propertyContext?.details.id;
    const selected = propertyId
      ? getPropertyContextById(propertyId)
      : undefined;

    return selected;
  });

  const { createShowHandler } = useSharedInfotipPresenter(
    SelectedPropertyInfotipBody
  );
  const { t } = useTranslation();
  const { date, dateFormat, time } = useBusinessDateTime();

  const onPointerClick = createShowHandler(
    {
      selectedProperty,
      businessDate: date,
      propertyTime: time,
    },
    {
      openingBehavior: OpeningBehavior.onClick,
      attachTo: `#${menuId}`,
      targetValue: TargetValueObject.mainMenu,
    }
  );

  return (
    <>
      <ContextDropdown
        id={menuId}
        selectedContext={selectedProperty}
        businessDate={date}
        dateFormat={dateFormat}
        propertyTime={time}
        color={color ?? getCurrentPropertyIdentifierColor()}
        tooltipContent={t('MENU.CONTEXT.PROPERTY.TOOLTIP')}
        onClick={onPointerClick}
      />
    </>
  );
};
