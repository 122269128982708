import { isLandingPage } from 'frontend-container/components/LandingPage/location';
import { processLandingPagePathname } from 'frontend-container/components/LandingPage/processLandingPagePathname';
import { injectGlobalMount } from 'frontend-container/utils/externalResources/shared';
import { registerApplication } from 'single-spa';

/**
 * The local module translates /landing-page/* url into a meaningful application url.
 * The intermediate step is needed because in order to set up the right landing page
 * we have to fetch configuration data first.
 */
const landingPageAppName = 'landing-page';

export const registerLandingPage = (): void => {
  registerApplication(
    landingPageAppName,
    injectGlobalMount({
      bootstrap: [],
      unmount: [],
      mount: [redirectToLandingPage],
    }),
    (location: Location) => isLandingPage(location.pathname)
  );
};

const redirectToLandingPage = async (): Promise<void> => {
  const landingPage = processLandingPagePathname(location.href);

  history.replaceState({}, '', landingPage);
};
