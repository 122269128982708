import { getFullMenu } from 'frontend-container/components/Menu/configuration';
import { CONFIGURATION_MENU_ID } from 'frontend-container/components/Menu/configuration/configuration';
import { useFavoritesContext } from 'frontend-container/components/Menu/store/favorites/context';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';

import { IconName } from '@ac/web-components';

type ModuleLinkMenuItem = {
  module: string;
  link: string;
};

export const FAVORITES_ID = 'favorites';

export const useFavoriteMenuItems = (): MenuElement | undefined => {
  const { favoritesItemsIds } = useFavoritesContext();
  const menuElements: MenuElement[] = getFullMenu();

  const getModuleLinkObject = (item: string[]): ModuleLinkMenuItem => {
    const getModule = () => {
      if (item[0] === 'menu-cro') {
        const croModule = item[1].replace('central-reservation-office/', '');

        return `menu-${croModule}`;
      }
      if (item[0] === 'configuration') {
        return CONFIGURATION_MENU_ID;
      }

      return item[0];
    };

    return {
      module: getModule(),
      link: `/${item[1]}`,
    };
  };

  const getFavoriteItems = (): MenuElementItem[] => {
    const favoriteItemIdToModuleAndLinkObject = favoritesItemsIds.map((id) =>
      getModuleLinkObject(id.split('-/'))
    );

    const favoriteModules = menuElements.filter((item) => {
      return favoriteItemIdToModuleAndLinkObject.find(
        (favorite) => favorite.module === item.id
      );
    });

    const favoriteItems = favoriteModules
      .flatMap((module) => module.items)
      .filter((item) => {
        return favoriteItemIdToModuleAndLinkObject.find(
          (favorite) => favorite.link === item.link
        );
      });

    return [...new Set(favoriteItems)];
  };

  const favoriteItems = getFavoriteItems();

  return {
    translation: 'MENU.MAIN_MENU.FAVORITES.TITLE',
    icon: IconName.star,
    id: FAVORITES_ID,
    items: favoriteItems,
  };
};
