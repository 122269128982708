import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  customerPermissionKeys,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService } from '@ac/library-utils/dist/services';
import { IconName } from '@ac/web-components';

export const CONFIGURATION_MENU_ID = 'menu-configuration';

export const configurationMenu: MenuElement = {
  translation: 'MENU.CONFIGURATION.TITLE',
  icon: IconName.settings,
  id: CONFIGURATION_MENU_ID,
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/users`,
      translation: 'MENU.CONFIGURATION.ITEMS.USERS',
      id: `${CONFIGURATION_MENU_ID}-users`,
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.identity.viewUsers,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.configuration
              .accessToConfigurationModule,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX};`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX};`,
      keyCode: 186,
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}`,
      aliases: [
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/organization-structure`,
        `${acConfig.newFrontendUrls.floorPlan}`,
      ],
      id: `${CONFIGURATION_MENU_ID}-dashboard`,
      translation: 'MENU.CONFIGURATION.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.configuration
              .accessToConfigurationModule,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.configuration.organizationUnit
              .viewOrganizationUnit,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}'`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}'`,
      keyCode: 222,
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/property/property-details`,
      translation: 'MENU.CONFIGURATION.ITEMS.PROPERTY_DETAILS',
      id: `${CONFIGURATION_MENU_ID}-property-details`,
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.configuration
              .accessToConfigurationModule,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      isUnderlined: true,
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/profile-center-structure`,
      translation: 'MENU.PROFILE_CENTERS.CONFIGURATION_TITLE',
      id: `${CONFIGURATION_MENU_ID}-profile-center`,
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.configuration.profileCenters.view,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: GeneralCustomerSettingsKeys.ProfileCenters,
          source: AccessSource.Tenant,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office`,
      translation: 'MENU.CONFIGURATION.ITEMS.CRO_DASHBOARD',
      id: `${CONFIGURATION_MENU_ID}-cro-dashboard`,
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.configuration
              .accessToConfigurationModule,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.configuration.centralReservationOffice
              .view,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: GeneralCustomerSettingsKeys.CentralReservationOffice,
          source: AccessSource.Tenant,
        },
      ],
    },
  ],
};

const configurationMenuInMainApplication: MenuElement = {
  ...configurationMenu,
  items: [
    ...configurationMenu.items,
    // {  2020-12-17 POs requested hiding WOrkflows from users for now
    //   link: `${acConfig.newFrontendUrls.workflows}`,
    //   translation: workflowsMenu.translation,
    //   permissionsConfiguration: workflowsMenu.permissionsConfiguration,
    // },
  ],
};

export const getConfigurationMenuInMainApplication = (): MenuElement => {
  // ACPR-91168
  // Menu.tsx validates every menu item based on systemCtx data
  // even though a menu item does not appear in the menu in the current location.
  // SystemUser has no data to validate the access to 'standard' configuration menu,
  // it has it's own configuration menu
  if (LoginService.isSuperUser()) {
    return getConfigurationMenuWithoutAccessCheck();
  }

  return configurationMenuInMainApplication;
};

const getConfigurationMenuWithoutAccessCheck = (): MenuElement => {
  const {
    items,
    allowedWhen, // eslint-disable-line
    featureToggles, // eslint-disable-line
    permissionsConfiguration, // eslint-disable-line
    settings, // eslint-disable-line
    ...menuElementWithoutAccessCheck
  } = configurationMenuInMainApplication;

  const newItemsWithoutAccessCheck = items.map((item) => {
    const {
      allowedWhen, // eslint-disable-line
      featureToggles, // eslint-disable-line
      permissionsConfiguration, // eslint-disable-line
      settings, // eslint-disable-line
      ...menuItemWithoutAccessCheck
    } = item;

    return menuItemWithoutAccessCheck;
  });

  return {
    ...menuElementWithoutAccessCheck,
    items: newItemsWithoutAccessCheck,
  };
};
