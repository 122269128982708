import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pathname403 } from 'frontend-container/components/Errors/Error403';
import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import {
  getAllowedMenuItems,
  getIsMenuComponentAllowed,
} from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Breadcrumbs } from 'frontend-container/components/Menu/components/Breadcrumbs/Breadcrumbs';
import {
  BreadcrumbsButton,
  BreadcrumbsButtonType,
} from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsButton';
import { Cashier } from 'frontend-container/components/Menu/components/Cashier/Cashier';
import { isCashierVisible } from 'frontend-container/components/Menu/components/Cashier/visibility';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { ContextManager } from 'frontend-container/components/Menu/components/Context/ContextManager';
import {
  ContextSelectButtonOptions,
  useContextMenuItems,
} from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import {
  getCroContexts,
  isCroEnabled,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { defaultDocumentTitle } from 'frontend-container/components/Menu/components/DocumentTitle/service';
import { GlobalSearchMenuIcon } from 'frontend-container/components/Menu/components/GlobalSearchMenuIcon/GlobalSearchMenuIcon';
import { GlobalSearchModal } from 'frontend-container/components/Menu/components/GlobalSearchModal/GlobalSearchModal';
import { useBrandingLogo } from 'frontend-container/components/Menu/components/Logo/useBrandingLogo';
import { MegaMenu } from 'frontend-container/components/Menu/components/MegaMenu/MegaMenu';
import {
  isBellVisible,
  NotificationBellContainer,
} from 'frontend-container/components/Menu/components/NotificationBell';
import {
  getProfileCentersContexts,
  isProfileCentersEnabled,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { SelectedProperty } from 'frontend-container/components/Menu/components/SelectedProperty/SelectedProperty';
import { TrainingBanner } from 'frontend-container/components/Menu/components/TrainingBanner/TrainingBanner';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { User } from 'frontend-container/components/Menu/components/User/User';
import { UserDocumentationButton } from 'frontend-container/components/Menu/components/UserDocumentation/UserDocumentation';
import { useIsWorkstationVisible } from 'frontend-container/components/Menu/components/Workstation/visibility';
import { WorkstationMenu } from 'frontend-container/components/Menu/components/Workstation/Workstation';
import {
  getAllowedCentralReservationOfficeMenu,
  getAllowedConfigurationMenu,
  getAllowedIntegrationsMenuElementsForSystemUser,
  getAllowedMainApplicationMenu,
  getAllowedProfileCenterMenu,
  getAllowedWorkflowsMenu,
  getFullMenu,
  getMainApplicationMenu,
} from 'frontend-container/components/Menu/configuration';
import { getSelectedMenuItem } from 'frontend-container/components/Menu/menuItems';
import {
  isBusinessContextDataGoingToChange,
  THEME_DARK_KEY,
} from 'frontend-container/components/Menu/service';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { useOverrideMenuHeight } from 'frontend-container/components/Menu/useOverrideMenuHeight';
import { useTrainingFeature } from 'frontend-container/components/Menu/useTrainingFeature';
import { getElementsWithSeparateExternalLinks } from 'frontend-container/components/Menu/utils/getElementsWithSeparateExternalLinks';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isModuleWithoutMainMenuEntry } from 'frontend-container/components/Menu/utils/isModuleWithoutMainMenuEntry';
import {
  isCentralReservationOfficeModule,
  isCroCreateOrEditReservationPathname,
} from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { isConfigurationv2TenantContextModule } from 'frontend-container/components/Menu/utils/modules/configurationv2';
import { isFloorPlanModule } from 'frontend-container/components/Menu/utils/modules/floorPlan';
import { isIntegrationsModule } from 'frontend-container/components/Menu/utils/modules/integrations';
import {
  isNotificationsTenantContextDashboard,
  isNotificationSubscriptions,
} from 'frontend-container/components/Menu/utils/modules/notifications';
import { isProfileCentersModule } from 'frontend-container/components/Menu/utils/modules/profileCenters';
import { isWorkflowsModule } from 'frontend-container/components/Menu/utils/modules/workflows';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { useMaintenancePanelPresenterContext } from 'frontend-container/components/panels/maintenancePanel/presenter/context';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  getReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import { emberPathNameAppNameMap } from 'frontend-container/config/emberPathnameAppNameMap';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { replaceWithPage } from 'frontend-container/shared/navigation/replaceWithPage';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';
import { getAppScopeFromPathname } from 'frontend-container/utils/getAppScopeFromPathname';
import { isEmberAppByAppName } from 'frontend-container/utils/isEmberApp';
import { isMac } from 'frontend-container/utils/isMac';
import { unloadApplication } from 'single-spa';

import { globalBusinessContextUpdatedEventCreator } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { useBreadcrumbsTitle } from '@ac/react-infrastructure';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  KeyboardKey,
  MenuBarSlot,
  MenuBarTheme,
  TargetValueObject,
} from '@ac/web-components';

import './Menu.scss';

let usedApp: string | undefined;

export const MenuV2 = (): JSX.Element => {
  const { t } = useTranslation();
  const [menuItems, setMenuItems] = useState<MenuElement[]>([]);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [contextMenuItems, setContextMenuItems] =
    useState<ContextSelectButtonOptions>();
  const [allAccessConfig, setAllAccessConfig] =
    useState<AllAccessConfiguration>();
  const { setContextsContext } = useContextsContext();
  const locationRef = useRef<string>(location.pathname);
  const [pageName, setPageName] = useState(defaultDocumentTitle);
  const [isGlobalSearchVisible, setIsGlobalSearchVisible] = useState(false);

  const { getContextMenuItems } = useContextMenuItems();

  const isNonProduction = useTrainingFeature();

  const fullMenuConfiguration = useMemo(() => getFullMenu(), []);

  const mappedFullMenuConfiguration = useMemo(() => {
    if (LoginService.isSuperUser()) {
      return allAccessConfig
        ? getAllowedIntegrationsMenuElementsForSystemUser(allAccessConfig)
        : [];
    }

    const allowedItems = allAccessConfig
      ? getAllowedMenuItems(getMainApplicationMenu(), allAccessConfig)
      : [];

    return getElementsWithSeparateExternalLinks(allowedItems);
  }, [allAccessConfig]);

  const mainMenuLogo = useBrandingLogo();
  const breadcrumbsTitle = useBreadcrumbsTitle();
  const [currentMenuElement, currentMenuItem] = getSelectedMenuItem(menuItems);
  const isDarkMode = Boolean(LocalStorage.getParsed(THEME_DARK_KEY));

  const croContexts = useMemo(() => getCroContexts(), []);
  const profileCentersContexts = useMemo(() => getProfileCentersContexts(), []);
  const propertyContexts = useMemo(() => getPropertyContexts(), []);

  const selectedCro: Context | undefined = isCroEnabled()
    ? selectInitialContext(
        croContexts,
        SessionService.getCentralReservationOfficeId()
      )
    : undefined;

  const selectedProfileCenter: Context | undefined = isProfileCentersEnabled()
    ? selectInitialContext(
        profileCentersContexts,
        SessionService.getProfileCenterId()
      )
    : undefined;

  const selectedProperty: Context | undefined = selectInitialContext(
    propertyContexts,
    SessionService.getPropertyId()
  );

  useEffect(() => {
    void updateRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (breadcrumbsTitle) {
      setPageName(breadcrumbsTitle);
    }
  }, [breadcrumbsTitle]);

  useEffect(() => {
    const eventBus = getCurrentGlobalEventBus();

    window.addEventListener('popstate', updateRoute);
    const unsubscribeBusinessContextUpdatedEvent = eventBus.subscribe(
      globalBusinessContextUpdatedEventCreator,
      () => updateRoute()
    );

    return (): void => {
      window.removeEventListener('popstate', updateRoute);
      unsubscribeBusinessContextUpdatedEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  useEffect(() => {
    window.addEventListener('keydown', toggleGlobalSearchModal);

    return (): void => {
      window.removeEventListener('keydown', toggleGlobalSearchModal);
    };
  });

  const toggleGlobalSearchModal = (event: KeyboardEvent): void => {
    const isInputEvent =
      event.target && !(event.target as HTMLElement).classList.contains('body');

    // block event when some element is focused and global search is closed
    if (isInputEvent && !isGlobalSearchVisible) {
      return;
    }

    const isCommandOrCtrlDown =
      (isMac && event.metaKey) || (!isMac && event.ctrlKey);

    if (isCommandOrCtrlDown && event.key === 'k') {
      event.preventDefault();
      event.stopPropagation();
      setIsGlobalSearchVisible(!isGlobalSearchVisible);
    }

    if (event.key === '/' && !isGlobalSearchVisible) {
      event.preventDefault();
      event.stopPropagation();
      setIsGlobalSearchVisible(true);
    }

    if (event.key === KeyboardKey.Escape) {
      setIsGlobalSearchVisible(false);
    }
  };

  const redirectWithoutMenuItemAccess = (
    allAccessConfiguration: AllAccessConfiguration,
    currentMenuElementSelected?: MenuElement
  ): boolean => {
    const isRouteNotFound =
      !currentMenuElementSelected &&
      allAccessConfiguration[allAccessConfiguration.currentSource].permissions
        .length &&
      !isModuleWithoutMainMenuEntry();

    if (isRouteNotFound) {
      replaceWithPage(pathname403);

      return true;
    }

    const [selectedMenuElement, selectedMenuItem] = getSelectedMenuItem(
      fullMenuConfiguration
    );

    const isMenuItemNotAllowed =
      selectedMenuItem &&
      !getIsMenuComponentAllowed(selectedMenuItem, allAccessConfiguration);

    const isMenuElementNotAllowed =
      selectedMenuElement &&
      !isModuleWithoutMainMenuEntry() &&
      !getIsMenuComponentAllowed(selectedMenuElement, allAccessConfiguration);

    if (isMenuItemNotAllowed || isMenuElementNotAllowed) {
      replaceWithPage(pathname403);

      return true;
    }

    return false;
  };

  const updateMenuItems = (
    allAccessConfiguration: AllAccessConfiguration
  ): MenuElement[] => {
    if (isErrorPage() && menuItems.length) {
      return menuItems;
    }

    let menuElements: MenuElement[];

    const isSystemUser = LoginService.isSuperUser();

    if (
      isConfigurationModule() ||
      isConfigurationv2TenantContextModule() ||
      isFloorPlanModule() ||
      isNotificationSubscriptions() ||
      isNotificationsTenantContextDashboard()
    ) {
      menuElements = getAllowedConfigurationMenu(allAccessConfiguration);
    } else if (!isSystemUser && isProfileCentersModule()) {
      menuElements = getAllowedProfileCenterMenu(allAccessConfiguration);
    } else if (!isSystemUser && isWorkflowsModule()) {
      menuElements = getAllowedWorkflowsMenu(allAccessConfiguration);
    } else if (!isSystemUser && isCentralReservationOfficeModule()) {
      menuElements = getAllowedCentralReservationOfficeMenu(
        allAccessConfiguration
      );
    } else if (isSystemUser && isIntegrationsModule()) {
      menuElements = getAllowedIntegrationsMenuElementsForSystemUser(
        allAccessConfiguration
      );
    } else {
      menuElements = getAllowedMainApplicationMenu(allAccessConfiguration);
    }

    const mappedElements = getElementsWithSeparateExternalLinks(menuElements);
    setMenuItems(mappedElements);

    return mappedElements;
  };

  const unloadEmberApplication = async (): Promise<void> => {
    const scope = getAppScopeFromPathname();
    if (
      !!usedApp &&
      emberPathNameAppNameMap[scope] !== usedApp &&
      isEmberAppByAppName(usedApp)
    ) {
      await unloadApplication(usedApp ?? '');
    }
    usedApp = emberPathNameAppNameMap[scope];
  };

  const updateRoute = async (event?: Event): Promise<void> => {
    const isPopstateEvent = event && event.type === 'popstate';
    if (isPopstateEvent) {
      const newPathname = location.pathname;
      if (newPathname === locationRef.current) {
        return;
      }
      locationRef.current = newPathname;
    }

    await unloadEmberApplication();
    // Only modules which are editable in global region care for readOnly mode.
    // Each time we enter into such module, if we are not in global region, we should be in readOnly mode.
    // That's why we active readOnly mode for modules other then the ones editable in global region.
    if (isReadOnlyRequired()) {
      const newValue = getReadOnlyByUser() ?? true;
      setReadOnlyMode(newValue);
    }

    if (isBusinessContextDataGoingToChange()) {
      return;
    }

    const allAccessConfiguration = getAllAccessConfiguration();
    setAllAccessConfig(allAccessConfiguration);

    const menuElements: MenuElement[] = updateMenuItems(allAccessConfiguration);

    setContextMenuItems(getContextMenuItems(allAccessConfiguration));

    setContextsContext(getContextMenuItems(allAccessConfiguration));

    const [element] = getSelectedMenuItem(menuElements);

    const isRedirected = redirectWithoutMenuItemAccess(
      allAccessConfiguration,
      element
    );

    if (isRedirected) {
      return;
    }
  };

  const { isWorkstationVisible } = useIsWorkstationVisible();

  const maintenancePanel = useMaintenancePanelPresenterContext();

  const openMaintenancePanel = async (): Promise<void> => {
    if (!maintenancePanel.state.isVisible) {
      await maintenancePanel.show();
    }
  };

  useOverrideMenuHeight(isNonProduction, true);

  const closeCallback = useCallback(() => setIsMenuVisible(false), []);

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-flex class="layout-direction">
        <>
          <ac-menu-bar
            id="menu-v2-bar"
            onLogoDoubleClickCallback={openMaintenancePanel}
            customLogoSrc={mainMenuLogo}
            theme={isDarkMode ? MenuBarTheme.dark : MenuBarTheme.light}
            onHamburgerClickCallback={(): void =>
              setIsMenuVisible(!isMenuVisible)
            }
            isOpen={isMenuVisible}
          >
            <ac-flex
              slot={MenuBarSlot.pageName}
              justifyContent={JustifyContent.flexStart}
              alignItems={AlignItems.center}
            >
              <BreadcrumbsButton
                id="menu-page-name"
                type={BreadcrumbsButtonType.items}
                menuItems={menuItems}
                subItems={currentMenuElement?.items}
                content={pageName || defaultDocumentTitle}
                showIcon={false}
                isMenuHeader
              />
            </ac-flex>
            <ac-flex alignItems={AlignItems.center} slot={MenuBarSlot.content}>
              {isCroCreateOrEditReservationPathname() && <SelectedProperty />}

              <ContextManager />

              <GlobalSearchMenuIcon
                onClickCallback={(): void => setIsGlobalSearchVisible(true)}
              />

              {isCashierVisible() && <Cashier />}
              {isWorkstationVisible && <WorkstationMenu />}
              {isBellVisible() && <NotificationBellContainer />}
              <UserDocumentationButton />
              {LoginService.isLoggedIn() &&
                userService.getCurrentUserDetails() && <User />}
            </ac-flex>
            <ac-flex
              slot={MenuBarSlot.breadcrumbs}
              justifyContent={JustifyContent.flexStart}
              alignItems={AlignItems.center}
            >
              <Breadcrumbs
                currentModule={currentMenuElement}
                buttonSelectOptions={contextMenuItems?.buttonSelectOptions}
                currentButtonOption={contextMenuItems?.currentButtonOption}
                pageTitle={pageName}
                selectedItem={currentMenuItem}
                menuItems={menuItems}
              />
            </ac-flex>
          </ac-menu-bar>
          <MegaMenu
            selectedCro={selectedCro}
            selectedProfileCenter={selectedProfileCenter}
            selectedProperty={selectedProperty}
            isVisible={isMenuVisible}
            onCloseCallback={closeCallback}
            allAccessConfiguration={allAccessConfig}
            mainMenuItems={mappedFullMenuConfiguration}
          />
          <ac-tooltip
            for="#menu-v2-bar-hamburger"
            text={t('MENU.MAIN_MENU.TOOLTIP')}
            targetValue={TargetValueObject.mainMenu}
          />
        </>

        {isGlobalSearchVisible && (
          <GlobalSearchModal
            selectedProperty={selectedProperty}
            selectedCro={selectedCro}
            selectedProfileCenter={selectedProfileCenter}
            mainApplicationMenu={mappedFullMenuConfiguration}
            onCloseCallback={(): void => setIsGlobalSearchVisible(false)}
          />
        )}
      </ac-flex>
      {isNonProduction && (
        <div className="container-banner">
          <TrainingBanner />
        </div>
      )}
    </ac-flex>
  );
};
