import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { handleMenuItemClick } from 'frontend-container/components/Menu/components/MegaMenu/handleMenuItemClick';
import { MEGA_MENU_ELEMENTS_WITH_SINGLE_OPTION } from 'frontend-container/components/Menu/components/MegaMenu/megaMenuConstants';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { parseMacShortcutsToViewMode } from 'frontend-container/components/Menu/utils/parseMacShortcusToViewMode';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import {
  ColorsPaletteHEX,
  IconName,
  MenuItemType,
  MenuSlot,
} from '@ac/web-components';

interface Props {
  isMac: boolean;
  menuSlot?: MenuSlot;
  menuElements: MenuElement[];
  selectedContextCode?: string;
  withoutHeader?: boolean;
  onCloseCallback?: () => void;
  areLabelsAlreadyTranslated?: boolean;
  onFavoriteClickCallback?: (id: string) => void;
  isEditMode: boolean;
  favoritesItemsIds: string[];
}

export const MegaMenuItem = ({
  isMac,
  menuElements,
  selectedContextCode,
  withoutHeader,
  onCloseCallback,
  areLabelsAlreadyTranslated = false,
  onFavoriteClickCallback,
  isEditMode,
  favoritesItemsIds,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const propertyId = SessionService.getPropertyId() ?? '';
  const tenantId = LoginService.authData()?.tenantId ?? '';

  return (
    <Fragment>
      {menuElements.map((menuElement) => {
        const isElementWithSingleOption =
          MEGA_MENU_ELEMENTS_WITH_SINGLE_OPTION.includes(menuElement.id);

        const firstSubitem = menuElement.items?.[0];

        const headerKey = isElementWithSingleOption
          ? firstSubitem.translation
          : menuElement.translation;

        const headerHotkey = !isElementWithSingleOption
          ? undefined
          : isMac
          ? parseMacShortcutsToViewMode(firstSubitem?.keyboardShortcutMac)
          : firstSubitem?.keyboardShortcutWindows;

        const headerId = `${menuElement.id}-${firstSubitem.link}`;

        const headerIsFavorite = favoritesItemsIds.indexOf(headerId) > -1;

        return (
          <Fragment key={headerKey}>
            {!withoutHeader && (
              <ac-menu-item
                id={headerId}
                icon={menuElement.icon}
                label={t(headerKey)}
                data-test-selector={menuElement.id}
                type={MenuItemType.header}
                isEditMode={isEditMode}
                link={
                  isElementWithSingleOption ? firstSubitem?.link : undefined
                }
                hotkey={headerHotkey}
                badge={
                  selectedContextCode
                    ? {
                        content: selectedContextCode,
                        color: ColorsPaletteHEX.gray200,
                      }
                    : undefined
                }
                onClickCallback={
                  isElementWithSingleOption
                    ? (id: string): void => {
                        if (isEditMode && onFavoriteClickCallback) {
                          onFavoriteClickCallback(id);
                        } else {
                          handleMenuItemClick(
                            firstSubitem?.link,
                            menuElement.items
                          );
                          onCloseCallback?.();
                        }
                      }
                    : undefined
                }
                isFavorite={headerIsFavorite}
                onFavoriteClickCallback={onFavoriteClickCallback}
              />
            )}
            {isElementWithSingleOption
              ? null
              : menuElement.items?.map((menuElementItem) => {
                  const translationKey = menuElementItem.translation;
                  const itemId = `${menuElement.id}-${menuElementItem.link}`;
                  const itemIsFavorite = favoritesItemsIds.indexOf(itemId) > -1;

                  const fullLink = menuElementItem.link
                    .replace(/:id/g, propertyId)
                    .replace(/:customerId/g, tenantId);

                  return (
                    <ac-menu-item
                      link={fullLink}
                      id={itemId}
                      key={menuElementItem.link}
                      data-test-selector={menuElementItem.id}
                      label={
                        areLabelsAlreadyTranslated
                          ? translationKey
                          : t(translationKey)
                      }
                      isEditMode={isEditMode}
                      onClickCallback={(id: string): void => {
                        if (isEditMode && onFavoriteClickCallback) {
                          onFavoriteClickCallback(id);
                        } else {
                          handleMenuItemClick(
                            menuElementItem.link,
                            menuElement.items
                          );
                          onCloseCallback?.();
                        }
                      }}
                      hotkey={
                        isMac
                          ? parseMacShortcutsToViewMode(
                              menuElementItem.keyboardShortcutMac
                            )
                          : menuElementItem.keyboardShortcutWindows
                      }
                      rightIcon={
                        menuElementItem.isExternal
                          ? IconName.externalLink
                          : undefined
                      }
                      isFavorite={itemIsFavorite}
                      onFavoriteClickCallback={onFavoriteClickCallback}
                      type={
                        // MenuItemType.favorite currently hides favorite icon, it will be changed in [ACPR-125733]
                        LoginService.isSuperUser()
                          ? MenuItemType.favorite
                          : MenuItemType.item
                      }
                    />
                  );
                })}
          </Fragment>
        );
      })}
    </Fragment>
  );
};
