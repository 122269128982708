import { Fragment, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BreadcrumbsButton,
  BreadcrumbsButtonType,
} from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsButton';
import { BreadcrumbsMobileView } from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsMobileView';
import { useBreadCrumbsOptionalSegmentDictionary } from 'frontend-container/components/Menu/components/Breadcrumbs/useBreadCrumbsOptionalSegmentDictionary';
import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { ContextSelectButton } from 'frontend-container/components/Menu/components/ContextSelectButton/ContextSelectButton';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { isCroContextVisible } from 'frontend-container/components/Menu/components/CroContext';
import {
  getCroContextById,
  getCroItems,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { useMenuShortcuts } from 'frontend-container/components/Menu/components/Item/useMenuShortcuts';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import {
  getProfileCentersContexts,
  getProfileCentersItems,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';
import {
  getPropertyContexts,
  getPropertyUnits,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { getMainApplicationMenuV2 } from 'frontend-container/components/Menu/configuration';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { getSortedMenuItemsFlat } from 'frontend-container/components/Menu/utils/getSortedMenuItems';
import { getUserUnitsDetails } from 'frontend-container/components/Prestep/getUserUnitsDetails';

import {
  ResponsiveViewport,
  useViewportContext,
  ViewBreakpoint,
} from '@ac/react-infrastructure';

export const breadcrumbsUnitItemId = 'breadcrumbs-unit-button';

interface Props {
  menuItems: MenuElement[];
  buttonSelectOptions?: ButtonContextOption[];
  currentButtonOption?: ButtonContextOption;
  pageTitle?: string;
  currentModule?: MenuElement;
  selectedItem?: MenuElementItem;
}

export const Breadcrumbs = ({
  menuItems,
  buttonSelectOptions,
  currentButtonOption,
  currentModule,
  pageTitle,
  selectedItem,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const allAllowedMenuItems = menuItems;

  const { propertyId, centralReservationOfficeId, profileCenterId } =
    useSessionContextTypeIds();

  const details = useMemo(() => {
    const croUnits = getCroItems();
    const properties = getPropertyUnits();
    const profileCenters = getProfileCentersItems();

    const userUnitsDetails = getUserUnitsDetails(
      properties,
      croUnits,
      profileCenters
    );

    return userUnitsDetails;
  }, []);

  const sortedMenuItems = useMemo((): MenuElement[] => {
    return getSortedMenuItemsFlat(allAllowedMenuItems);
  }, [allAllowedMenuItems]);

  const getUnit = (): Context | undefined => {
    if (isPropertyContextVisible() && propertyId) {
      const propertyContexts = getPropertyContexts();

      return propertyContexts.find(({ id }) => id === propertyId);
    }

    if (isCroContextVisible() && centralReservationOfficeId) {
      return getCroContextById(centralReservationOfficeId);
    }

    if (isProfileCentersContextVisible() && profileCenterId) {
      return getProfileCentersContexts().find(
        ({ id }) => id === profileCenterId
      );
    }

    return;
  };

  const unit = getUnit();

  const {
    state: { responsiveBreakpoint },
  } = useViewportContext();

  const isMobileMenuHidden = responsiveBreakpoint > ViewBreakpoint.SM;

  const menuItemTranslation = t(currentModule?.translation ?? '');
  const selectedItemTranslation = t(selectedItem?.translation ?? '');

  const isContextButtonVisible =
    buttonSelectOptions && buttonSelectOptions.length >= 2;

  const isUnitBreadcrumbVisible = useMemo((): boolean => {
    switch (unit?.type) {
      case ContextType.PROPERTY: {
        return !details.isSinglePropertyUser;
      }
      case ContextType.CRO: {
        return !details.isSingleCroUser;
      }
      case ContextType.PROFILE_CENTER: {
        return !details.isSingleProfileCenterUser;
      }

      default:
        return false;
    }
  }, [
    unit?.type,
    details.isSingleCroUser,
    details.isSingleProfileCenterUser,
    details.isSinglePropertyUser,
  ]);

  const pathname = window.location.pathname;
  const search = window.location.search;
  const optionalSegment = useBreadCrumbsOptionalSegmentDictionary(
    pathname,
    search
  );

  const menuElements = useRef(getMainApplicationMenuV2());
  const propertyCurrentModule =
    currentModule && unit?.type === ContextType.PROPERTY
      ? currentModule
      : undefined;

  const handleShortcut = async (link: string): Promise<void> => {
    const menuElement = menuElements.current.find((element) =>
      element.items.find((item) => item.link === link)
    );
    await handleMenuItemRedirect(link, menuElement?.items);
  };

  useMenuShortcuts(false, handleShortcut);

  return (
    <ResponsiveViewport>
      <ContextSelectButton
        buttonSelectOptions={buttonSelectOptions}
        currentButtonOption={currentButtonOption}
        showDivider={false}
        hideIcon
      />

      {!isMobileMenuHidden && (
        <BreadcrumbsMobileView
          isContextButtonVisible={isContextButtonVisible}
          isUnitBreadcrumbVisible={isUnitBreadcrumbVisible}
          menuItemName={menuItemTranslation}
          pageTitle={pageTitle}
          unitCode={unit?.code}
          menuItems={sortedMenuItems}
          subItems={currentModule?.items ?? []}
          selectedItemTranslation={selectedItemTranslation}
        />
      )}

      {isMobileMenuHidden && (
        <Fragment>
          {isUnitBreadcrumbVisible && (
            <BreadcrumbsButton
              content={unit?.code}
              showIcon={isContextButtonVisible}
              type={BreadcrumbsButtonType.context}
              id={breadcrumbsUnitItemId}
            />
          )}
          {menuItemTranslation && (
            <BreadcrumbsButton
              id="breadcrumbs-module-button"
              content={menuItemTranslation}
              showIcon={isContextButtonVisible || isUnitBreadcrumbVisible}
              type={BreadcrumbsButtonType.module}
              menuItems={sortedMenuItems}
              currentModule={propertyCurrentModule}
            />
          )}
          {optionalSegment && (
            <BreadcrumbsButton
              id="breadcrumbs-single-button"
              content={t(optionalSegment.title)}
              transparent={true}
              optionalLink={optionalSegment.link}
            />
          )}
        </Fragment>
      )}
    </ResponsiveViewport>
  );
};
