import { isGlobalConfigurationModule } from 'frontend-container/components/Menu/utils/modules/globalFeatures';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const globalFeaturesAppName = 'frontend-global-features';
export const registerGlobalFeatures = (): void => {
  const host = window.location.host;
  if (host.includes('localhost')) {
    // This app is a playground available ONLY for development purposes on local dev
    registerApplication(
      globalFeaturesAppName,
      () =>
        loadReactApp(
          acConfig.frontendUrls.globalFeatures,
          globalFeaturesAppName
        ),
      (location: Location) => isGlobalConfigurationModule(location.pathname)
    );
  }
};
