import { buildRemoteSupportApplicationUrl } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/commands/supportApplications';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  EffectiveSettingDetails,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { RemoteSupportUrlSettingValue } from '@ac/library-api/dist/businessContext/customer/settings/model/general/remoteSupportUrl';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

type RemoteSupportUrlSetting = EffectiveSettingDetails<{
  value: RemoteSupportUrlSettingValue;
}>;

export const runRemoteSupportSession = (sessionKey: string): void => {
  const hostAddressSavedInSetting = findRemoteSupportHostAddress();
  const isChinaRegion = getCurrentRegionCode().toLowerCase().startsWith('cn');
  const regionKey = isChinaRegion ? 'cn' : 'global';

  const url = buildRemoteSupportApplicationUrl({
    regionKey,
    sessionKey,
    hostAddressSavedInSetting,
  });

  window.open(url, '_blank');
};

const findRemoteSupportHostAddress = (): string | undefined => {
  const propertySettings =
    getPropertyContextData()?.settings.effectiveSettingsDetails;
  const setting = propertySettings?.find(
    (item) => item.code === GeneralCustomerSettingsKeys.RemoteSupportUrl
  ) as RemoteSupportUrlSetting | undefined;

  return setting?.value?.value?.host;
};
