import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { croLandingPagePathname } from 'frontend-container/components/LandingPage';
import {
  landingPageDestination,
  profileCenterLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage/location';
import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context/context';
import { ContextComponent } from 'frontend-container/components/Menu/components/Context/ContextComponent';
import { ContextMapper } from 'frontend-container/components/Menu/components/Context/contextMapper';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { useDateTimeContext } from 'frontend-container/components/Menu/components/Context/useDateTimeContext';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { ContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import {
  getCroContexts,
  isCroEnabled,
  redirectToPropertyOrNoPermissionsPage,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { getProfileCentersContexts } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { getNewUrlForHousekeepingModule } from 'frontend-container/components/Menu/components/PropertyContext/getNewUrlForHousekeepingModule';
import { getNewUrlForReservationsModuleIfNeeded } from 'frontend-container/components/Menu/components/PropertyContext/getNewUrlForReservationsModuleIfNeeded';
import {
  getCurrentPropertyIdentifierColor,
  getPropertyContexts,
  redirectToCroOrNoPermissionsPage,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { reservationsMenu } from 'frontend-container/components/Menu/configuration/reservations';
import { getActiveContextOption } from 'frontend-container/components/Menu/utils/getActiveContextOption';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isCashieringBillingModule } from 'frontend-container/components/Menu/utils/modules/cashiering';
import { isHousekeepingModule } from 'frontend-container/components/Menu/utils/modules/housekeeping';
import { profileCentersPath } from 'frontend-container/components/Menu/utils/modules/profileCenters';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { PresenterResultType } from 'frontend-container/publicApi';
import { getCurrentUnitFromSessionService } from 'frontend-container/shared/businessContext/getCurrentUnit';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import {
  getOriginForNewRegion,
  getUrlForNewRegion,
  getUrlWithContextParam,
} from 'frontend-container/utils/region/getUrlForNewRegion';
import { redirectOnCroSelectIfContextRegionDiffer } from 'frontend-container/utils/region/redirectOnCroSelectIfContextRegionDiffer';
import { redirectToProperRegionIfNecessary } from 'frontend-container/utils/region/redirectToProperRegionIfNecessary';
import { isEmpty } from 'lodash';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import {
  getCurrentRegionCode,
  getGlobalRegionCode,
} from '@ac/library-utils/dist/utils/multi-region';
import { AcSelectOption, AcSelectValue } from '@ac/web-components';

interface Props {
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
  color?: string;
}

export const ContextManager = ({
  onLoadingStart,
  onLoadingEnd,
  color,
}: Props): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState<Context | undefined>(undefined);

  const activeContextOption = getActiveContextOption();

  const { propertyId } = useSessionContextTypeIds();

  const { t } = useTranslation();
  const dateTimeHook = useDateTimeContext(selected?.type);

  const enableLoading = (): void => {
    setIsLoading(true);
    onLoadingStart?.();
  };

  const disableLoading = (): void => {
    setIsLoading(false);
    onLoadingEnd?.();
  };

  const initialize = async (): Promise<void> => {
    enableLoading();

    const tenantId = LoginService.authData()?.tenantId;
    let selectedContext: Context | undefined;

    switch (activeContextOption) {
      case ContextOption.cro: {
        if (!tenantId) {
          throw new Error('Tenant ID is undefined');
        }

        const croContexts = getCroContexts();
        const propertyContexts = getPropertyContexts();

        if (isEmpty(croContexts) || !isCroEnabled()) {
          return redirectToPropertyOrNoPermissionsPage(
            propertyContexts?.length
          );
        }

        selectedContext = selectInitialContext(
          croContexts,
          SessionService.getCentralReservationOfficeId()
        );

        break;
      }
      case ContextOption.property: {
        const croContexts = getCroContexts();
        const propertyContexts = getPropertyContexts();

        if (isEmpty(propertyContexts)) {
          return redirectToCroOrNoPermissionsPage(croContexts);
        }

        selectedContext = selectInitialContext(
          propertyContexts,
          SessionService.getPropertyId()
        );

        break;
      }
      case ContextOption.profileCenter: {
        if (!tenantId) {
          throw new Error('Tenant ID is undefined');
        }

        const profileCentersContexts = getProfileCentersContexts();

        if (isEmpty(profileCentersContexts)) {
          return redirectToCroOrNoPermissionsPage(profileCentersContexts);
        }

        selectedContext = selectInitialContext(
          profileCentersContexts,
          SessionService.getProfileCenterId()
        );

        break;
      }
      case ContextOption.configuration: {
        const propertyContexts = getPropertyContexts();

        selectedContext = selectInitialContext(
          propertyContexts,
          SessionService.getPropertyId()
        );

        break;
      }
    }

    if (
      selectedContext &&
      !redirectToProperRegionIfNecessary(selectedContext)
    ) {
      const selectedUnit =
        ContextMapper.contextToBusinessContextUnitIdentifier(selectedContext);

      await selectUnitInBusinessContext({
        ...getCurrentUnitFromSessionService(),
        ...selectedUnit,
      });

      setSessionContext(selectedContext);
      setSelected(selectedContext);
    }

    disableLoading();
  };

  const handleSelectProperty = async (
    option: Context,
    discardUnsavedChanges = false
  ): Promise<void> => {
    if (
      selected?.id === option.id &&
      activeContextOption !== ContextOption.configuration
    ) {
      return;
    }

    if (
      !discardUnsavedChanges &&
      window.ACP?.container?.hasUnsavedChanges?.()
    ) {
      const modal = window.ACP?.container?.modals?.unsavedChanges;
      const isUnsavedChangesModalVisible = modal?.isVisible ?? false;

      if (!isUnsavedChangesModalVisible) {
        const result = await modal?.show();

        if (result?.type === PresenterResultType.Confirmed) {
          await handleSelectProperty(option, true);
        } else {
          return;
        }
      }
    }

    const currentPropertyId = SessionService.getPropertyId();
    SessionService.setCashierNumber(undefined);
    SessionService.setPropertyCashierNumber(currentPropertyId, undefined);

    setSelected(option);
    setSessionContext(option);

    const currentRegionCode = getCurrentRegionCode();
    const optionRegionCode = option.regionCode ?? currentRegionCode;

    if (optionRegionCode !== currentRegionCode) {
      const region = findRegionData(optionRegionCode ?? getGlobalRegionCode());

      const originForNewRegion = getOriginForNewRegion(
        region?.code ?? '',
        region?.domain ?? ''
      );

      if (isHousekeepingModule() && option.id) {
        const param = getNewUrlForHousekeepingModule(
          option.id,
          propertyLandingPagePathname,
          originForNewRegion
        );

        return window.location.replace(
          `${getUrlWithContextParam(param)}?${landingPageDestination}=${
            window.location.pathname
          }`
        );
      }

      const newReservationsUrl = getNewUrlForReservationsModuleIfNeeded(
        window.location,
        originForNewRegion
      );
      if (newReservationsUrl) {
        return window.location.replace(
          getUrlWithContextParam(newReservationsUrl)
        );
      }

      return window.location.replace(
        getUrlForNewRegion(region?.code ?? '', region?.domain ?? '', option)
      );
    }

    if (activeContextOption !== ContextOption.property) {
      return window.location.replace(propertyLandingPagePathname);
    }

    if (isCashieringBillingModule()) {
      const newUrlForCashieringModule = `${propertyLandingPagePathname}${acConfig.newFrontendUrls.cashiering}/dashboard`;

      return window.location.replace(
        `${newUrlForCashieringModule}?${landingPageDestination}=${acConfig.newFrontendUrls.cashiering}/dashboard`
      );
    }

    if (isHousekeepingModule() && option.id) {
      return window.location.replace(
        `${getNewUrlForHousekeepingModule(
          option.id,
          propertyLandingPagePathname,
          window.location.origin
        )}?${landingPageDestination}=${window.location.pathname}`
      );
    }

    const newReservationsUrl = getNewUrlForReservationsModuleIfNeeded(
      window.location
    );
    if (newReservationsUrl) {
      const reservationsDashboardPath =
        reservationsMenu.items.find(
          ({ code }) => code === 'ReservationDashboard'
        )?.link ?? '';

      return window.location.replace(
        `${propertyLandingPagePathname}?${landingPageDestination}=${reservationsDashboardPath}`
      );
    }

    if (window.location.search) {
      return window.location.replace(
        `${propertyLandingPagePathname}?${landingPageDestination}=${window.location.pathname}`
      );
    }

    return window.location.replace(
      `${propertyLandingPagePathname}?${landingPageDestination}=${window.location.pathname}`
    );
  };

  const handleSelectCro = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSelected(context);
    setSessionContext(context);

    const isRedirectedToNewCroRegion =
      redirectOnCroSelectIfContextRegionDiffer(context);

    if (!isRedirectedToNewCroRegion && tenantId) {
      history.replaceState({}, '', croLandingPagePathname);

      void selectUnitInBusinessContext({
        tenantId,
        centralReservationOfficeId: context.id,
        propertyId,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', croLandingPagePathname);
      }
    }

    navigateToPage(croLandingPagePathname);
  };

  const handleSelectConfiguration = (
    selectedOption: Array<AcSelectOption<AcSelectValue>>
  ): void => {
    const selectedOptionValue = selectedOption[0]?.value ?? '';
    const pathToLoad = `${window.location.origin}${selectedOptionValue}`;

    if (selectedOptionValue === profileCenterLandingPagePathname) {
      handleUnsavedChangesRedirect(
        false,
        `${window.location.origin}${profileCenterLandingPagePathname}`,
        async () =>
          await handleMenuItemRedirect(pathToLoad, undefined, undefined, true)
      );
      window.location.replace(
        `${window.location.origin}${profileCenterLandingPagePathname}`
      );

      return;
    }

    handleUnsavedChangesRedirect(
      false,
      pathToLoad,
      async () =>
        await handleMenuItemRedirect(pathToLoad, undefined, undefined, true)
    );
    handleMenuItemRedirect(pathToLoad);
  };

  const handleSelectProfileCenter = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSelected(context);
    setSessionContext(context);

    if (tenantId) {
      history.replaceState({}, '', profileCentersPath);

      void selectUnitInBusinessContext({
        tenantId,
        profileCenterId: context.id,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', profileCentersPath);
      }
    }

    navigateToPage(profileCenterLandingPagePathname);
  };

  const handleSelect = (
    option: Context | Array<AcSelectOption<AcSelectValue>>,
    discardUnsavedChanges = false,
    tabId = ''
  ): Promise<void> | void => {
    switch (tabId) {
      case ContextOption.cro: {
        return handleSelectCro(option as Context);
      }
      case ContextOption.property: {
        return handleSelectProperty(option as Context, discardUnsavedChanges);
      }
      case ContextOption.configuration: {
        return handleSelectConfiguration(
          option as Array<AcSelectOption<AcSelectValue>>
        );
      }
      case ContextOption.profileCenter: {
        return handleSelectProfileCenter(option as Context);
      }
      default: {
        throw new Error('Context not defined');
      }
    }
  };

  useEffect(() => {
    void initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shouldReinit =
      (ContextOption.property !== activeContextOption &&
        selected?.type === ContextType.PROPERTY) ||
      (ContextOption.cro !== activeContextOption &&
        selected?.type === ContextType.CRO) ||
      (ContextOption.profileCenter !== activeContextOption &&
        selected?.type === ContextType.PROFILE_CENTER) ||
      isContextChangedFromOutside();

    if (shouldReinit) {
      void initialize();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContextOption, selected?.type]);

  const getColor = (): string | undefined => {
    if (selected?.type === ContextType.PROPERTY) {
      return color ? color : getCurrentPropertyIdentifierColor();
    }

    return;
  };

  const isContextChangedFromOutside = (): boolean => {
    const id = selected?.id;

    return !(
      id === SessionService.getPropertyId() ||
      id === SessionService.getCentralReservationOfficeId() ||
      id === SessionService.getProfileCenterId()
    );
  };

  if (LoginService.isSuperUser()) {
    return null;
  }

  return isLoading ? (
    <ac-loader-covering />
  ) : (
    <ContextComponent
      selectedContext={selected}
      useDateTime={dateTimeHook}
      onSelect={handleSelect}
      color={getColor()}
      dateLabel={t('MENU.CONTEXT.PROPERTY.DATE')}
      timeLabel={t('MENU.CONTEXT.PROPERTY.TIME')}
      shouldActiveConfiguration={
        activeContextOption === ContextOption.configuration &&
        selected?.type === ContextType.PROPERTY
      }
      distanceFromTarget={3}
    />
  );
};
