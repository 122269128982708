// import { isConfigurationContextVisible } from 'frontend-container/components/Menu/components/ConfigurationContext/isConfigurationContextVisible';
import { getCurrentPropertyRegionCode } from 'frontend-container/components/Menu/components/PropertyContext/service';
import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  formatDate,
  getShortDateFormat,
  getTimeFormat,
  ShortDateFormats,
  TimeFormats,
} from 'frontend-container/utils/dateUtils';

import {
  BaseApi,
  CurrentPropertyDateTime,
  getCustomConfigWithPropertyRegionSpecificHost,
} from '@ac/library-api';
import { CurrentDateApi } from '@ac/library-api/dist/api/v0/propertyManagement';

export interface FormattedDate {
  date: string;
  dateFormat: string;
}

export const getFormattedBusinessDate = (): FormattedDate => {
  const propertyContext = getPropertyContextData();
  const customerContext = getCustomerContextData();

  if (!propertyContext || !propertyContext.businessDate) {
    return {
      date: '',
      dateFormat: '',
    };
  }

  const dateFormat =
    getShortDateFormat(propertyContext?.details) ||
    getShortDateFormat(customerContext?.details, ShortDateFormats.YYYYMMDD);

  return {
    date: formatDate(propertyContext.businessDate, dateFormat.toUpperCase()),
    dateFormat,
  };
};

export interface FormattedTime {
  time: string;
  currentDateTime: CurrentPropertyDateTime;
}

export const getCurrentPropertyDateTime =
  async (): Promise<CurrentPropertyDateTime> => {
    BaseApi.clearCache(
      new RegExp(CurrentDateApi.ApiCacheKeyPropertyLocalDateTime)
    );
    const currentPropertyRegionCode = getCurrentPropertyRegionCode();
    const currentPropertyDateTime =
      (await CurrentDateApi.getPropertyLocalDateTime({
        customConfig: currentPropertyRegionCode
          ? getCustomConfigWithPropertyRegionSpecificHost(
              currentPropertyRegionCode
            )
          : undefined,
      })) as CurrentPropertyDateTime;

    return currentPropertyDateTime;
  };

export const getFormattedTime = async (): Promise<FormattedTime> => {
  const customerDetails = getCustomerContextData()?.details;
  const propertyDetails = getPropertyContextData()?.details;

  const timeFormat =
    getTimeFormat(propertyDetails) ||
    getTimeFormat(customerDetails, TimeFormats.H24);

  const currentDateTime = await getCurrentPropertyDateTime();
  const time = formatDate(currentDateTime.localPropertyDateTime, timeFormat);

  return { time, currentDateTime };
};
