type Region = 'cn' | 'global';

type RegionConfiguration = {
  [Key in Region]: string;
};

const regions: RegionConfiguration = {
  cn: 'https://support.shijigroup.cn/',
  global: 'https://support-intl.shijicloud.com/',
};

interface Props {
  regionKey: Region;
  sessionKey: string;
  hostAddressSavedInSetting: string | undefined;
}

export const buildRemoteSupportApplicationUrl = (props: Props): string =>
  `${findRemoteSupportHostAddress(props)}api/start_session?short_key=${
    props.sessionKey
  }`;

const findRemoteSupportHostAddress = ({
  regionKey,
  hostAddressSavedInSetting,
}: Props): string => {
  if (
    !hostAddressSavedInSetting ||
    !isValidHostAddress(hostAddressSavedInSetting)
  ) {
    return regions[regionKey];
  }

  return hostAddressSavedInSetting.endsWith('/')
    ? hostAddressSavedInSetting
    : `${hostAddressSavedInSetting}/`;
};

const isValidHostAddress = (host: string): boolean =>
  /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/.test(
    host
  );
